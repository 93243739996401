import { LosseContent, LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useInView } from 'framer-motion'
import { useRef } from 'react'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentBigImage({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const imageRef = useRef(null)
  const contentRef = useRef(null)
  const titleRef = useRef(null)

  const imageVisible = useInView(imageRef, { once: true })
  const contentVisible = useInView(contentRef, { once: true })
  const titleVisible = useInView(titleRef, { once: true })

  return (
    <section data-component="ContentBigImage" className="section">
      <div className="container">
        <h2
          ref={titleRef}
          className={clsx(
            titleVisible && 'animate-fade-up animate-delay-100 animate-duration-500 animate-ease-in-out',
            titleVisible ? 'opacity-100' : 'opacity-0',
            'text-5xl relative z-10 ml-8 md:ml-16 -mb-7 md:-mb-10 xl:-mb-14 sm:text-7xl xl:text-[112px] text-site-text pb-3 children-strong:font-bold font-light font-site-accent'
          )}
        >
          <LosseContent>{fields.specialtitle}</LosseContent>
        </h2>
        <div ref={imageRef}>
          <LossePlaatjie
            maxwidth={1400}
            className={clsx(
              imageVisible && 'animate-fade-up animate-delay-200 animate-duration-500 animate-ease-in-out',
              imageVisible ? 'opacity-100' : 'opacity-0'
            )}
            src={fields.image}
          />
        </div>

        <div className="flex justify-end md:pr-8 lg:pr-14 xl:pr-28">
          <div
            ref={contentRef}
            className={clsx(
              contentVisible && 'animate-fade-up animate-delay-500 animate-duration-500 animate-ease-in-out',
              contentVisible ? 'opacity-100' : 'opacity-0',
              'bg-site-accent md:-mt-[50px] lg:-mt-[150px] xl:-mt-[250px] py-6 sm:py-10 xl:py-12 px-6 sm:px-10 xl:px-20 max-md:w-full md:max-w-screen-sm'
            )}
          >
            <Content className="content children-p:text-white">{fields.description}</Content>
            {fields.links && (
              <div className="flex items-center gap-2 mt-8">
                {fields.links.map((link, index) => (
                  <Button className={clsx(index >= 1 && 'btn--alt', 'btn--outline')} key={index} to={link?.link?.url || '/'}>
                    {link?.link?.title}
                  </Button>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
