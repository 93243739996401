import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Button from '~/components/elements/Button'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'

export default function BannerHome({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const scrollDown = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth'
    })
  }

  return (
    <section data-component="BannerHome" className="relative z-20">
      <div className="h-[calc(100dvh_-_100px)] sm:min-h-[800px] max-w-[2000px] mx-auto relative z-10 flex items-center justify-center">
        <LossePlaatjie
          loading="eager"
          maxWidth={2000}
          className="absolute animate-fade-up animate-duration-500 animate-ease-in-out left-0 top-0 w-full object-cover h-full"
          src={fields.image}
        />
        {fields.links && (
          <div className="flex sm:flex-row flex-col animate-fade-up animate-delay-100 animate-duration-500 animate-ease-in-out relative z-10 items-center gap-6">
            {fields.links.map((link, index) => (
              <Button
                className={clsx(index >= 1 && 'btn--accent', 'btn !min-w-[216px] py-3 flex justify-center !text-lg !font-bold')}
                key={index}
                to={link?.link?.url || '/'}
              >
                {link?.link?.title}
              </Button>
            ))}
          </div>
        )}
        <div
          onClick={() => scrollDown()}
          className="absolute animate-fade-up animate-delay-300 animate-duration-500 animate-ease-in-out cursor-pointer bottom-0 left-0 right-0 mx-auto w-[110px] h-[70px] flex justify-center items-center bg-site-accent-light"
        >
          <svg
            className="animate-bounce duration-1000"
            width="24"
            height="36"
            viewBox="0 0 24 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_709_6029)">
              <path
                d="M0 8.05702L9.52 17.564C10.1772 18.2182 11.0667 18.5854 11.994 18.5854C12.9213 18.5854 13.8108 18.2182 14.468 17.564L24 8.04602L21.879 5.92902L12.348 15.446C12.2542 15.5398 12.1271 15.5924 11.9945 15.5924C11.8619 15.5924 11.7348 15.5398 11.641 15.446L2.121 5.94002L0 8.05702Z"
                fill="#3A3A3A"
              />
            </g>
            <g clipPath="url(#clip1_709_6029)">
              <path
                d="M0 20.057L9.52 29.564C10.1772 30.2182 11.0667 30.5854 11.994 30.5854C12.9213 30.5854 13.8108 30.2182 14.468 29.564L24 20.046L21.879 17.929L12.348 27.446C12.2542 27.5398 12.1271 27.5924 11.9945 27.5924C11.8619 27.5924 11.7348 27.5398 11.641 27.446L2.121 17.94L0 20.057Z"
                fill="#3A3A3A"
              />
            </g>
            <defs>
              <clipPath id="clip0_709_6029">
                <rect width="24" height="24" fill="white" />
              </clipPath>
              <clipPath id="clip1_709_6029">
                <rect width="24" height="24" fill="white" transform="translate(0 12)" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </section>
  )
}
